import { helpers, required } from "vuelidate/lib/validators"
const validInteger = helpers.regex('validInteger', /^[0-9.-]+$/)

export const Validations = {
  // method: {
  //   validRequired(string) {
  //     if(string === '') {
  //       '入力してください'
  //     }
  //   }
  // },
  validations: {
    photo:{
      shootDate: {
        required
      },
      shootDateSecond: {
        required
      },
      nextShoot: {
        required
      },
      daterange: {
        start: {
          required
        },
        end: {
          required
        }
      }
    },
    // details: {
    //   $each: {
    //     detailName: {
    //       required
    //     }
    //   }
      
    // },
    
    facility: {
      facilityType: {
        required
      },
      facilityName: {
        required
      },
      address: {
        required
      },
      lat: {
        validInteger,
        required
      },
      lng: {
        validInteger,
        required
      },
      correctionValue: {
        validInteger
      }
    }
    
  }
}
  
  

