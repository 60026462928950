<template>
<div>
  <Header/> 
  <b-container 
    style="padding-top:70px;"
  > 
    <b-row>
      <b-col>
        <h1>施設情報編集</h1>
      </b-col>
      <b-col 
        v-if="role === '0'"
      >
        <b-button 
          variant="danger" 
          @click="deleteFacilityModal(facility.id)"
        >
          施設を削除する
        </b-button>
      </b-col>
    </b-row>

    <b-row 
      class="mt-2"
    >
      <b-col 
        md="1" 
        cols="3"
      >
        施設名：
      </b-col>
      <b-col 
        md="5" 
        cols="9"
      >
        <b-form-input 
          class="col-md-5" 
          id="FacilityName"  
          v-model="$v.facility.facilityName.$model"
          @blur="$v.facility.facilityName.$touch()"
        />
        <span 
          v-if="$v.facility.facilityName.$error"
        >
          <small 
            class="text-danger" 
            v-if="!$v.facility.facilityName.required"
          >
            施設名を入力してください
          </small>
        </span>
      </b-col>
    </b-row>

    <b-row 
      class="mt-2"
    >
      <b-col 
        md="1" 
        cols="3"
      >
        住所：
      </b-col>
      <b-col 
        md="5" 
        cols="9"
      >
        <b-form-input
          type="text" 
          id="address"
          v-model="$v.facility.address.$model"
          @blur="$v.facility.address.$touch()"
          @change="getLatLng"
        />
        <span 
          v-if="$v.facility.address.$error"
        >
          <small 
            class="text-danger" 
            v-if="!$v.facility.address.required"
          >
            住所を入力してください
          </small>
        </span>
      </b-col>
    </b-row>


    <b-row 
      class="mt-2"
    >
      <div 
        id="map" 
        style=" height:400px; width:100%; "
      ></div>
    </b-row>

    <b-row 
      class="mt-2"
    >
      <b-col 
        class="col-md-5 "
      >
        <b-button 
          @click="addDetail" 
          variant="success"
        >
          ＋追加情報
        </b-button>
      </b-col>
      <b-col 
        v-if="addDetailsFlag"
      >
        <b-button 
          variant="primary" 
          @click=" postAddDetailsModel()"
        >
          追加情報を登録する
        </b-button>
      </b-col>
    </b-row>

    <b-tabs 
      v-model="tabIndex"
      class="mt-2"
    >
      <b-tab 
        title="代表情報"
      >
        <b-row 
          class="mt-2"
        >
          <b-col 
            md="1" 
            cols="3"
          >
            大区分：
          </b-col >
          <b-col 
            md="5" 
            cols="9"
          >
            <select 
              class="form-select" 
              name="facilityType"
              v-model="$v.facility.facilityType.$model"
              @blur="$v.facility.facilityType.$touch()"
            >
              <option 
                value=""
              >
                選択してください
              </option>
              <option 
                v-for="facilityType in facilityTypes"
                :key="facilityType.inClassificationID"
                :value="facilityType"
              >
                {{ facilityType.vcClassificationName }}
              </option>
            </select>
            <span 
              v-if="$v.facility.facilityType.$error"
            >
              <small 
                class="text-danger" 
                v-if="!$v.facility.facilityType.required"
              >
                施設区分を選択してください
              </small>
            </span>
          </b-col> 
        </b-row>

        <b-row 
          v-if="middleFacility"
          class="mt-2"
        >
          <b-col 
            md="1" 
            cols="3"
          >
            中区分：
          </b-col >
          <b-col 
            md="5" 
            cols="9"
          >
            <select 
              class="form-select" 
              name="facilityType"
              v-model="facility.middleFacilityType"
            >
              <option 
                value=""
              >
                選択してください
              </option>
              <option 
                v-for="middleType in selectMiddleTypes" 
                :key="'middleFacilityType-' + middleType.inClassificationID"
                :value="middleType"
              >
                {{ middleType.vcMiddleClassName }}
              </option>
            </select>
          </b-col>
        </b-row>

        <b-row 
          class="mt-2"
        >
          <dl 
            class="row mt-1"
          >
            <b-col 
              cols="3" 
              md="1"
            >
              <dd>
                タグ：
              </dd>
            </b-col>
            <b-col 
              cols="9" 
              md="5"
            >
              <dd 
                v-if="facilityTags.length"
              >
                <div 
                  v-for="facilityTag in facilityTags"
                  :key="facilityTag.vcTagName + facilityTag.insort"
                  class="text-nowrap form-check-inline"
                >
                  <input
                    name="tags"
                    v-model="facility.tags"
                    class="form-check-input" 
                    type="checkbox"  
                    :id="facilityTag.vcTagName"
                    :value="facilityTag.inTagID"
                  >
                  <label 
                    class="form-check-label" 
                    :for="facilityTag.vcTagName"
                  >
                    {{ facilityTag.vcTagName }}
                  </label>  
                </div>
              </dd>
              <dd v-else>
                {{ message.tag }}
              </dd>
            </b-col>
          </dl>   
        </b-row>

        <dl 
          class="row mt-2" 
          v-if="facility.isTrain"
        >
          <b-col 
            cols="3" 
            md="1"
          >
            <dd>
              路線：
            </dd>
          </b-col>
          <b-col 
            cols="9" 
            md="5"
          >
            <dd 
              v-if="trainDatas.length"
            >
              <div 
                v-for="trainData in trainDatas" 
                :key="trainData.vcKindaikaRouteName + trainData.iRouteNo" 
                class="text-nowrap form-check-inline"
              >
                <input 
                  name="train"  
                  v-model="facility.trainInfo"
                  class="form-check-input" 
                  type="checkbox"
                  :id="trainData.vcKindaikaRouteName" 
                  :value="trainData"
                >
                <label 
                  class="form-check-label" 
                  :for="trainData.vcKindaikaRouteName"
                >
                  {{ trainData.vcKindaikaRouteName }}
                </label>
              </div>
            </dd>
            <dd v-else>
              {{ message.train }}
            </dd>
          </b-col>
        </dl> 

        
        <b-row 
          class="mt-2"
        >
          <b-col 
            md="1" 
            cols="3"
          >
            緯度：
          </b-col>
          <b-col 
            md="5" 
            cols="9"
          >
            <b-form-input
              type="text" 
              id="lat"
              v-model="$v.facility.lat.$model"
              @blur="$v.facility.lat.$touch()"
            />
            <span 
              v-if="$v.facility.lat.$error"
            >
              <small 
                class="text-danger" 
                v-if="!$v.facility.lat.required"
              >
                緯度を入力してください
              </small>
              <small 
                class="text-danger" 
                v-if="!$v.facility.lat.validInteger"
              >
                半角数字、「.」「-」のみ入力可能です。
              </small>
            </span>
          </b-col>
        </b-row>

        <b-row 
          class="mt-2"
        >
          <b-col 
            md="1" 
            cols="3"
          >
            経度：
          </b-col>
          <b-col 
            md="5" 
            cols="9"
          >
            <b-form-input 
              type="text" 
              id="lng"
              v-model="$v.facility.lng.$model"
              @blur="$v.facility.lng.$touch()"
            />
            <span 
              v-if="$v.facility.lng.$error"
            >
              <small 
                class="text-danger" 
                v-if="!$v.facility.lng.required"
              >
                経度を入力してください
              </small>
              <small 
                class="text-danger" 
                v-if="!$v.facility.lng.validInteger"
              >
                半角数字、「.」「-」のみ入力可能です。
              </small>
            </span>
          </b-col>
        </b-row>

        <b-row 
          class="mt-2" 
          v-if="role == 0"
        >
          <b-col 
            md="1" 
            cols="3"
          >
            補正値：
          </b-col>
          <b-col 
            md="5" 
            cols="9"
          >
            <b-form-input 
              type="text" 
              id="correctionValue"  
              v-model="$v.facility.correctionValue.$model"
              @blur="$v.facility.correctionValue.$touch()"
            />
            <span 
              v-if="$v.facility.correctionValue.$error"
            >
              <small 
                class="text-danger"  
                v-if="!$v.facility.correctionValue.validInteger"
              >
                半角数字、「.」「-」のみ入力可能です。
              </small>
            </span>
          </b-col>
        </b-row>

        <b-row 
          class="mt-2"
        >
          <b-col 
            md="1" 
            cols="3"
          >
            代表施設備考：
          </b-col>
          <b-col 
            md="5" 
            cols="9"
          >
            <b-form-textarea 
              rows="3"
              max-rows="6" 
              id="remarks"
              v-model="facility.remarks"
            />
          </b-col>
        </b-row>
      </b-tab>

      <b-tab 
        v-for="(detail, index) in details" 
        :key="'detail' + index"
        :title="detail.detailName"
      >
        <b-row >
          <b-row 
            class="mt-2"
          >
            <b-col 
              md="1" 
              cols="3"
            >
              名称：
            </b-col>
            <b-col 
              md="5" 
              cols="9"
            >
              <b-form-input
                type="text"  
                v-model="detail.detailName"
              />
            </b-col>
            <b-col 
              style="text-align:right;" 
              v-if="role === '0'"
            >
              <b-button 
                variant="danger" 
                @click="deleteDetailsModal([detail.id],detail.detailName)"
              >
                追加情報を削除する
              </b-button>
            </b-col>
            
          </b-row>

          <b-row 
            class="mt-2">
            <b-col 
              md="1" 
              cols="3"
            >
                緯度：
            </b-col>
            <b-col 
              md="5" 
              cols="9"
            >
              <b-form-input
                type="text" 
                v-model="detail.lat"
              />
            </b-col>
          </b-row>

          <b-row 
            class="mt-2"
          >
            <b-col 
              md="1" 
              cols="3"
            >
              経度：
            </b-col>
            <b-col 
              md="5" 
              cols="9"
            >
              <b-form-input 
                type="text" 
                v-model="detail.lng"
              />
            </b-col>
          </b-row>

          <b-row 
            class="mt-2" 
            v-if="role == 0"
          >
            <b-col 
              md="1" 
              cols="3"
            >
              補正値：
            </b-col>
            <b-col 
              md="5" 
              cols="9"
            >
              <b-form-input 
                type="text"   
                v-model="detail.correctionValue"
              />
            </b-col>
          </b-row>

          <b-row 
            class="mt-2"
          >
            <b-col 
              md="1" 
              cols="3"
            >
              備考：
            </b-col>
            <b-col 
              md="5" 
              cols="9"
            >
              <b-form-textarea 
                rows="3"
                max-rows="6" 
                id="remarks"
                v-model="detail.remarks"
              />
            </b-col>
          </b-row>
        </b-row>
      </b-tab>

      <b-tab 
        v-for="(addDetail, index) in addDetails" 
        :key="'add' + index"
        :title="addDetails[index].addDetailActive ?'追加情報' +(addDetail.id+1) : ''"
      >
        <b-row 
          v-if="addDetails[index].addDetailActive"
        >
          <b-row 
            class="mt-2"
          >
            <b-col 
              md="1" 
              cols="3"
            >
              名称：
            </b-col>
            <b-col 
              md="5" 
              cols="9"
            >
              <b-form-input
                type="text"  
                v-model="addDetail.detailName"
              />
              <span 
                v-if="addDetail.validDetailName"
              >
                <small 
                  class="text-danger" 
                >
                  {{addDetail.validDetailName}}
                </small>
              </span>
            </b-col>
            <b-col 
              style="text-align:right;" 
              v-if="role === '0'"
            >
              <b-button 
                variant="danger" 
                @click="deleteAddDetailsForm(index,addDetail.id)"
              >
                削除
              </b-button>
            </b-col>
          </b-row>

          <b-row 
            class="mt-2" 
            hidden
          >
            <b-col 
              md="1" 
              cols="3"
            >
                緯度：
            </b-col>
            <b-col 
              md="5" 
              cols="9"
            >
              <b-form-input
                type="text" 
                v-model="addDetail.lat"
              />
              <span 
                v-if="addDetail.validDetailLat"
              >
                <small 
                  class="text-danger" 
                >
                  {{addDetail.validDetailLat}}
                </small>
              </span>
            </b-col>
          </b-row>

          <b-row 
            class="mt-2" 
            hidden
          >
            <b-col 
              md="1" 
              cols="3"
            >
              経度：
            </b-col>
            <b-col 
              md="5" 
              cols="9"
            >
              <b-form-input 
                type="text" 
                v-model="addDetail.lng"
              />
              <span 
                v-if="addDetail.validDetailLng"
              >
                <small 
                  class="text-danger" 
                >
                  {{addDetail.validDetailLng}}
                </small>
              </span>
            </b-col>
          </b-row>

          <b-row 
            class="mt-2" 
            v-if="role == 0" 
            hidden
          >
            <b-col 
              md="1" 
              cols="3"
            >
              補正値：
            </b-col>
            <b-col 
              md="5" 
              cols="9"
            >
              <b-form-input 
                type="text"   
                v-model="addDetail.correctionValue"
              />
            </b-col>
          </b-row>

          <b-row 
            class="mt-2"
          >
            <b-col 
              md="1" 
              cols="3"
            >
              備考：
            </b-col>
            <b-col 
              md="5" 
              cols="9"
            >
              <b-form-textarea 
                rows="3"
                max-rows="6" 
                id="remarks"
                v-model="addDetail.remarks"
              />
            </b-col>
          </b-row>
        </b-row>
      </b-tab>
    </b-tabs>
    
    <b-row 
      class="mt-3"
    >
      <b-button 
        @click="updateFacilityDetail"
        variant="primary" 
        class="btn btn-block" 
      >
        施設情報を更新する
      </b-button>
    </b-row>
  </b-container>  
</div>  
</template>

<script>
import Header from '../../components/Header.vue'
import { Validations } from "../../mixins/validation"
import { Apimixin } from '../../mixins/api'
import { Maploader } from '../../mixins/mapload'

export default {
  title: '施設情報更新',
  
  mixins:[Validations, Apimixin, Maploader],
  data() {
    return {
      facility: {
        id:'',
        detailsID:'',
        facilityType: [],
        middleFacilityType:[],
        facilityName: '',
        address: '',
        tags:[],
        lat: '',
        lng: '',
        correctionValue: '',
        remarks: '',
        trainInfo:[],
        routeName:'',
        isActive:false,
        isTrain:false
      },
      details:[
        {
        id:'',
        detailName: '',
        lat: '',
        lng: '',
        correctionValue: '',
        remarks:'',
        detailsActive:false
        }
      ],
      addDetails:[
        {
          id:0,
          detailName: '',
          lat: '',
          lng: '',
          correctionValue: '',
          remarks:'',
          pinID:'',
          pin:false,
          addDetailActive:false,
          validDetailName:'',
          validDetailLat:'',
          validDetailLng:''
        }
      ],
      updateTag:[],
      updateType:{},
      role: '',
      userID:'',
      mappins: [],
      middleFacility:false,
      selectMiddleTypes:[],
      facilityDetail:"",
      facilityDetailID:[],
      addDetailID:0,
      oldDetail:[],
      updateData:[],
      oldFacilityName:'',
      oldFacilityLat:'',
      oldFacilityLng:'',
      oldFacilityCorrectionVal:'',
      oldFacilityAddress:'',
      oldFacilityNote:'',
      oldFacilityType:'',
      oldTags:[],
      oldTrain:[],
      oldDetailsData:[],
      newFacilityData:{
        facilityID:'',
        detailsID:'',
        facilityName:'',
        address:'',
        lat:'',
        lng:'',
        correctionValue:'',
        note:'',
        facilityType:''
      },
      newFacilityDetailsData:[],
      tagActive:false,
      addDetailsFlag:false,
      detailIndex:0,
      tabIndex:0,
      tabFlag:false,
      validLatLng:/^[0-9.-]+$/
    }
  },
  components: {
    Header,
  },
  created() {
    this.updateFacilityFlag = true
    //施設詳細情報取得
    this.facilityDetail = this.$route.params.facilityDetail
    //アクティブな詳細画面でアクティブにしていたタブNo取得
    this.detailIndex = this.$route.params.tabIndex
    //既存の緯度経度情報を取得
    this.facility.lat = this.facilityDetail.master[0].doLatitude
    this.facility.lng = this.facilityDetail.master[0].doLongitude

    //施設区分情報取得
    this.getFacilityType();

  },
  mounted() {
    this.role = sessionStorage.getItem('role')
    this.userID = sessionStorage.getItem('userId');
    //マップ描画
    this.mapInit(this.facility.lat, this.facility.lng);
    
    if(this.facility.isTrain) {
      this.getTrainInfo();
    }
    //区分情報を取得し表示
    this.$watch('facilityTypes', function() {
      for(let i = 0;i < this.facilityTypes.length;i++) {
        if(this.updateType.inParentClassID === 0) {
          if(this.facilityTypes[i].inClassificationID === this.updateType.inClassificationID) {
            this.facility.facilityType = this.facilityTypes[i]
          }
        } else {
          if(this.facilityTypes[i].inClassificationID === this.updateType.inParentClassID) {
            this.facility.facilityType = this.facilityTypes[i]
          }
        }
      }
    })
    //中区分があれば取得し表示
    this.$watch('middleFacilityTypes', function() {
      for(let i = 0;i < this.middleFacilityTypes.length;i++) {
        if(this.updateType.inParentClassID !== 0) {
          if(this.middleFacilityTypes[i].inClassificationID === this.updateType.inClassificationID) {
            this.facility.middleFacilityType = this.middleFacilityTypes[i]
          }
        }
      }
    })
    //タグ情報を取得しチェックボックスにチェック
    var unwatch =  this.$watch('facilityTags', function() {
      if(this.facilityDetail.tags[0].inTagID !== null ) {
        for(let val in this.facilityTags){
          for(let i in this.oldTags) {
            if(this.facilityTags[val].inTagID === this.oldTags[i]) {
              this.facility.tags.push(this.oldTags[i])
              this.tagActive = true
              unwatch()
            }
          }
        }
      } 
    })
    //路線タグ取得しチェック
    var deletwatch =  this.$watch('trainDatas', function() {
      if(this.facilityDetail.train[0].iRouteNo!== null ) {
        for(let val in this.trainDatas){
          for(let i in this.oldTrain) {
            if(this.trainDatas[val].iRouteNo === this.oldTrain[i].iRouteNo) {
              this.facility.trainInfo.push(this.oldTrain[i])
              this.tagActive = true
              deletwatch()
            }
          }
        }
      } 
    })
    //タブが表示されたかチェック
    this.$watch('tabFlag', function() {
      this.getTabIndex()
    })
  },
  methods: {
     //施設中区分取得
    getMiddleFacility() {
      for(let i = 0;i < this.middleFacilityTypes.length; i++) {
        if(this.middleFacilityTypes[i].inParentClassID === this.facility.facilityType.inClassificationID) {
          this.middleFacility = true
          this.selectMiddleTypes.push(this.middleFacilityTypes[i])
        }
      }
    },
    //追加情報追加
    addDetail() {
      if(this.addDetails[0].addDetailActive) {
        this.addDetailID++
        const additionalForm = {
          id:this.addDetailID,
          detailName: '',
          lat: this.facility.lat,
          lng: this.facility.lng,
          correctionValue: '',
          remarks:'',
          pinID:'',
          pin:false,
          addDetailActive:true,
          validDetailName:'',
          validDetailLat:'',
          validDetailLng:''
        }
        this.addDetails.push(additionalForm)
      } else {
        this.addDetails[0].lat = this.facility.lat
        this.addDetails[0].lng = this.facility.lng
        this.addDetails[0].addDetailActive = true
      }
    },
    //追加情報入力フォーム削除
    deleteAddDetailsForm(index,id){
      if(index !== 0) {
        if(confirm('追加情報入力フォームを削除しますか？')) {
          for(let i = 0; i < this.mappins.length;i++) {
            if(this.mappins[i]._options.id === id) {
                this.mappins[i].setVisible(false)
              }
            }
          this.mappins.splice(this.addDetails[index].pinID,1)
        }
        for(let i = 0;i < this.addDetails.length; i++) {
          if(this.addDetails[i].detailName === '' && this.addDetails[i].addDetailActive === false) {
            this.addDetailsFlag = false
          }
        }
        this.addDetails.splice(index,1)
      } else {
        if(confirm('追加情報入力フォームを削除しますか？')) {
          for(let i = 0; i < this.mappins.length;i++) {
            if(this.mappins[i]._options.id === id) {
                this.mappins[i].setVisible(false)
              }
          }
          this.mappins.splice(this.addDetails[index].pinID,1)
        }
      }
        this.addDetails[index].detailName = '';
        this.addDetails[index].lat = '';
        this.addDetails[index].lng = '';
        this.addDetails[index].pin = false;
        this.addDetails[index].addDetailActive = false;
        this.addDetails[index].validDetailName='';
        this.addDetails[index].validDetailLat='';
        this.addDetails[index].validDetailLng='';
      for(let i = 0;i < this.addDetails.length; i++) {
        if(this.addDetails[i].detailName === '' && this.addDetails[i].addDetailActive === false) {
          this.addDetailsFlag = false
        }
      }
    },
    //追加情報の表示
    addForm(id,name,lat,lng,correctionValue,remarks) {
      const additionalForm = {
        id:id,
        detailName: name,
        lat: lat,
        lng: lng,
        correctionValue: correctionValue,
        remarks:remarks,
      }
      this.details.push(additionalForm)
    },
    
    //施設情報取得処理
    getSelectFacilityData() {
      this.oldFacilityAddress = this.facilityDetail.master[0].vcFacilityAdd
      this.oldFacilityLat = this.facilityDetail.master[0].doLatitude
      this.oldFacilityLng = this.facilityDetail.master[0].doLongitude
      this.oldFacilityCorrectionVal = this.facilityDetail.master[0].vcCorrectionVal

      if(this.facilityDetail.tags[0].inTagID !== null){
        for(let i = 0;i < this.facilityDetail.tags.length; i++) {
          this.oldTags.push(this.facilityDetail.tags[i].inTagID)
        }
      }
      
      if(this.facilityDetail.train[0].iRouteNo !== null) {
        for(let i = 0;i < this.facilityDetail.train.length; i++) {
          this.oldTrain.push(this.facilityDetail.train[i])
        }
      }
      
      this.facility.address = this.facilityDetail.master[0].vcFacilityAdd
      this.facility.id = this.facilityDetail.master[0].inFacilityID

      this.updateType['inClassificationID'] = this.facilityDetail.master[0].inClassificationID
      this.updateType['vcClassificationName'] = this.facilityDetail.master[0].vcClassificationName
      this.updateType['inParentClassID'] = this.facilityDetail.master[0].inParentClassID
      this.updateType['inParentMiddleClassID'] = this.facilityDetail.master[0].inParentMiddleClassID
      this.updateType['inSort'] = this.facilityDetail.master[0].classSort
      this.updateType['vcMiddleClassName'] = this.facilityDetail.master[0].vcMiddleClassName
      this.updateType['vcSubClassName'] = this.facilityDetail.master[0].vcSubClassName
      
      for(let i = 0;i < this.facilityDetail.master.length; i++) {
      this.updateTag.push(this.facilityDetail.master[i].inTagID)
      }
      
      for(let i = 0; i < this.facilityDetail.master.length; i++) {
        this.facilityDetailID.push(this.facilityDetail.master[i].inFacilityDetailsID)

        if(this.facilityDetail.master[i].inFacilityDetailsID === this.facilityDetail.details[this.facilityDetailID[i]][0].inFacilityDetailsID) {
          this.facilityDetail.details[this.facilityDetailID[i]][0]['doLatitude'] = this.facilityDetail.master[i].doLatitude
          this.facilityDetail.details[this.facilityDetailID[i]][0]['doLongitude'] = this.facilityDetail.master[i].doLongitude
          this.facilityDetail.details[this.facilityDetailID[i]][0]['vcCorrectionVal'] = this.facilityDetail.master[i].vcCorrectionVal
        }

        if(this.facilityDetail.details[this.facilityDetailID[i]][0].boExample == 0 ) {
          this.oldFacilityName = this.facilityDetail.details[this.facilityDetailID[i]][0].vcFacilityDetailName
          this.oldFacilityNote = this.facilityDetail.details[this.facilityDetailID[i]][0].txNote

          this.facility.detailsID = this.facilityDetail.details[this.facilityDetailID[i]][0].inFacilityDetailsID
          this.facility.facilityName = this.facilityDetail.details[this.facilityDetailID[i]][0].vcFacilityDetailName
          this.facility.correctionValue = this.facilityDetail.details[this.facilityDetailID[i]][0].vcCorrectionVal
          this.facility.remarks = this.facilityDetail.details[this.facilityDetailID[i]][0].txNote
        } else {
          this.oldDetail.push(this.facilityDetail.details[this.facilityDetailID[i]][0])
        }
      }
      if(this.oldDetail.length !== 0) {
        let timer = setInterval(() => {
          if(window.navitime){
            clearInterval(timer);
            this.details[0].id = this.oldDetail[0].inFacilityDetailsID
            this.details[0].detailName = this.oldDetail[0].vcFacilityDetailName
            this.details[0].lat = this.oldDetail[0].doLatitude
            this.details[0].lng = this.oldDetail[0].doLongitude
            this.details[0].correctionValue  = this.oldDetail[0].vcCorrectionVal
            this.details[0].remarks = this.oldDetail[0].txNote
            this.details[0].detailsActive = true

            for(let i = 1; i < this.oldDetail.length;i++) {
              this.addForm(this.oldDetail[i].inFacilityDetailsID, this.oldDetail[i].vcFacilityDetailName, this.oldDetail[i].doLatitude,
              this.oldDetail[i].doLongitude, this.oldDetail[i].vcCorrectionVal, this.oldDetail[i].txNote)
            }
            for(let i = 0; i < this.oldDetail.length;i++) {
              this.createDetailmappin(
                this.oldDetail[i].vcFacilityDetailName,this.oldDetail[i].inFacilityDetailsID,
                this.oldDetail[i].doLatitude,this.oldDetail[i].doLongitude
              )
            }
          }
        },500)
      }
      this.tabFlag = true
    },

    //該当のタブへ遷移
    getTabIndex() {
      let timer = setInterval(() => {
        if(this.tabIndex == 0){
          clearInterval(timer);
          this.tabIndex = this.detailIndex
        }
      },500)
    },
    //変更された値をチェック
    checkDifferenceFacility() {
      this.newFacilityData.facilityName = ''
      this.newFacilityData.address = ''
      this.newFacilityData.lat = ''
      this.newFacilityData.lng = ''
      this.newFacilityData.correctionValue = ''
      this.newFacilityData.note = ''
      this.newFacilityData.detailsID = ''
      this.newFacilityData.facilityID = ''
      this.newFacilityData.facilityType = ''
      
      if(this.oldFacilityName !== this.facility.facilityName) {
        this.newFacilityData.facilityName = this.facility.facilityName
        this.newFacilityData.detailsID = this.facility.detailsID
      }
      if(this.oldFacilityAddress !== this.facility.address) {
        this.newFacilityData.address = this.facility.address
      }

      if(this.facility.middleFacilityType === '') {
        if(this.updateType.inClassificationID !== this.facility.facilityType.inClassificationID) {
          this.newFacilityData.facilityType = this.facility.facilityType.inClassificationID
        }
      } else {
        if(this.updateType.inClassificationID !== this.facility.middleFacilityType.inClassificationID) {
          this.newFacilityData.facilityType = this.facility.middleFacilityType.inClassificationID
        }
      }
      
      if(this.oldFacilityLat !== this.facility.lat) {
        this.newFacilityData.lat = this.facility.lat
      }
      if(this.oldFacilityLng !== this.facility.lng) {
        this.newFacilityData.lng = this.facility.lng
      }
      if(this.oldFacilityCorrectionVal !== this.facility.correctionValue) {
        this.newFacilityData.correctionValue = this.facility.correctionValue
      }
      if(this.oldFacilityNote !== this.facility.remarks) {
        this.newFacilityData.note = this.facility.remarks
      }
      if(this.newFacilityData.lat !== '' || this.newFacilityData.lng !== '' ||
        this.newFacilityData.correctionValue !== '' || this.newFacilityData.note !== '') {
        this.newFacilityData.detailsID = this.facility.detailsID
      }
    },

    //施設情報削除
    deleteFacilityModal(facilityID) {
      // console.log(facilityID)
      this.$swal({
        title: '施設情報削除',
        html: `「${ this.oldFacilityName }」の施設情報を削除しますか？`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '確定',
        cancelButtonText:'キャンセル'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: '処理中',
            html: '処理終了まで画面はそのままにしてください。', 
            allowOutsideClick : false , 
            showConfirmButton: false, 
            onBeforeOpen: () => {
              this.$swal.showLoading()
            }
          }); 
          this.deleteFacilityData(facilityID)
        }
      })
    },

    //追加情報削除
    deleteDetailsModal(detailsID,detailName) {
      this.$swal({
        title: '追加情報削除',
        html: `「${ this.oldFacilityName } - ${ detailName }」の追加情報を削除しますか？`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: '確定',
        cancelButtonText:'キャンセル'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            title: '処理中',
            html: '処理終了まで画面はそのままにしてください。', 
            allowOutsideClick : false , 
            showConfirmButton: false, 
            onBeforeOpen: () => {
              this.$swal.showLoading()
            }
          }); 
          this.deleteDetailsData(detailsID)
        }
      })
    },

    //追加情報の登録
    postAddDetailsModel(){
      let result = true
      for(let i = 0;i < this.addDetails.length;i++) {
        if(this.addDetails[i].addDetailActive) { 
          if(this.addDetails[i].validDetailName != '') {
            result = false
          }  
        }
      }
      if(result) {
        this.$swal({
          title: '追加情報の登録',
          html: `追加情報を登録しますか？`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '確定',
          cancelButtonText:'キャンセル'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal({
              title: '処理中',
              html: '処理終了まで画面はそのままにしてください。', 
              allowOutsideClick : false , 
              showConfirmButton: false, 
              onBeforeOpen: () => {
                this.$swal.showLoading()
              }
            }); 
            this.postAddDetails()
          }
        })
      } else {
        this.$swal({
          title: `新規追加情報に未入力項目、もしくは入力不備があります。`,
          icon: 'error',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '閉じる',
        })
      }
    },

    //施設情報更新
    updateFacilityDetail() {
      this.checkDifferenceFacility()
      let result = true
      for(let i = 0;i < this.addDetails.length;i++) {
        if(this.addDetails[i].addDetailActive) { 
          if(this.addDetails[i].validDetailName != '') {
            result = false
          }  
        }
      }
      if(result) {
        this.$swal({
          title: '施設情報更新',
          html: `「${ this.facility.facilityName }」の施設情報を更新しますか？`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '確定',
          cancelButtonText:'キャンセル'
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal({
              title: '処理中',
              html: '処理終了まで画面はそのままにしてください。', 
              allowOutsideClick : false , 
              showConfirmButton: false, 
              onBeforeOpen: () => {
                this.$swal.showLoading()
              }
            }); 
            this.updateFacilityData(this.newFacilityData,this.details,this.oldDetail)
          }
        })
      } else {
        this.$swal({
          title: `新規追加情報に未入力項目、もしくは入力不備があります。`,
          icon: 'error',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '閉じる',
        })
      }
    },

  },
  
  watch: {
    'facilityDetail':function() {
      this.getSelectFacilityData();
    },
    'facility.facilityType': function() {
      this.getTag()
      this.facility.tags = [];
      this.middleFacility = false;
      this.selectMiddleTypes = [];
      this.facility.middleFacilityType = "";
      this.getMiddleFacility();
      if(this.facility.facilityType.inClassificationID == 2) {
        this.facility.isTrain = true
      } else {
        this.facility.isTrain = false
        this.facility.trainInfo = []
      }
    },
    
    'facility.facilityName': function() {
        this.trainDatas = [];
        this.getTrainInfo();
    },
    addDetails: {
      handler:function() {
        for(let i = 0; i < this.addDetails.length;i++) {
          if(this.addDetails[i].lat != "") {
            if(this.addDetails[i].addDetailActive === true) {
              if(this.addDetails[i].pin === false) {
                this.createDetailmappin(
                  '追加情報'+(this.addDetails[i].id+1),this.addDetails[i].id,
                  this.addDetails[i].lat,this.addDetails[i].lng,'add'
                )
                this.addDetails[i].pin = true
                this.addDetails[i].pinID = this.mappins.length-1
              }
            }
          }
          if(this.addDetails[i].detailName !== '' && this.addDetails[i].addDetailActive === true) {
            this.addDetailsFlag = true
          } 
          if(this.addDetails[i].detailName.length === 0) {
              this.addDetails[i].validDetailName = '名称を入力してください。'
          }else {
            this.addDetails[i].validDetailName = ''
          }
        }
      },
      deep:true
    },
  }
}
</script>


<style scoped>
.formLabel {
  display: inline-block;
  width: 140px;
  text-align: center;
}
</style>